const AdminReports = {

    // Retrieve the names of the User Settings
    getSettingsNameList() {
        return new Promise((resolve, reject) => {
            $.ajax({
                type: 'POST',
                url: '/get_user_settings',
                headers: {'X-CSRF-TOKEN': $('meta[name="csrf_token"]').attr('content')},
                success: (response) => {
                    resolve(response)
                },
                error: (error) => {
                    reject(error)
                }
            })
        })
    },

    // Retrieve all table columns
    getColumns() {
        return new Promise((resolve, reject) => {
            $.ajax({
                type: 'POST',
                url: '/get_all_report_columns',
                headers: {'X-CSRF-TOKEN': $('meta[name="csrf_token"]').attr('content')},
                success: (response) => {
                    resolve(response)
                },
                error: (error) => {
                    reject(error)
                }
            })
        })
    },

    // Retrieve User selected Columns
    getDisplayCols() {
        const display_cols = []
        
        $('.column-headers th .column-name').each( function() {
            const content = $(this).data('col_id')
            display_cols.push(content)
        })
        return display_cols
    },

    // Render interface for toggling table columns
    renderColumnFields() {
        const display_cols = AdminReports.getDisplayCols()
        console.log("display_cols", display_cols)

        AdminReports.getColumns().then((response) => {
            console.log("getcolumns response", response)
            const result = response.columns.map((column) => {
                return `<label for="${ column }"><input id="${ column }" type="checkbox" name="col[]" value="${ column }" ${ (display_cols.includes(column)) ? 'checked' : '' } > ${ column }</label>`
            })
            $('#column_selector .modal-body').html(result)
        })
        .catch((error) => {
            console.log("js error", error)
        })
    },


    async validateCreateReportForm() {

        // global so we can reach it from inside promises
        var valid = false

        // wait and validate the domain or fail
        const domain = $('#createDomainReportForm input#domain').val()
        if(domain) {
            const domainCheck = await AdminReports.validateDomain(domain)

            if(domainCheck.startsWith('Error:')) {
                console.log('domain bad: ', domainCheck)
                $('p.warning').text('There was a problem with the domain.')
                $('#createDomainReportForm input#domain').addClass('failed').focus()
                $('#submitDomain').attr('disabled', false)
            } else {
                console.log('domain good: ', domainCheck)
                $('#createDomainReportForm input#domain').addClass('success')
                // Response has the properly formatted domain, so put it back
                $('#createDomainReportForm input#domain').val(domainCheck)

                const total = $('#createDomainReportForm .page_url').length
                if(total == 1 && !$('#createDomainReportForm .page_url').val()) {
                    $('input.page_url:first-child()').addClass('failed').focus()
                    $('p.warning').text('Please add at least one subpage. Use "/" for the home page.')
                    $('#submitDomain').attr('disabled', false)
                                                         
                } else {
                    $('#createDomainReportForm .page_url').each( async function(index) {       
                        var thisPage = $(this)
                        const pagedir = $(this).val()
                        let url = '';
                        // make sure it's not empty
                        if(pagedir) {
                            // combine domain and page. Avoid '//' between domain and 1st level
                            if(domain.charAt(domain.length-1) == '/' && pagedir.charAt(0) == '/') {
                                // remove first character from the pagedir
                                url = domain+pagedir.substring(1)
                            } else {
                                url = domain+pagedir
                            }
                            if(url) {
                                const pageResults = await AdminReports.validateDomain(url)

                                if(pageResults.startsWith('Error:')) {
                                    thisPage.addClass('failed')
                                    valid = false
                                    $('p.warning').text('There was a problem validating one or more of the pages.')
                                    thisPage.focus()
                                    $('#createDomainReportForm').addClass('failed')
                                    $('#submitDomain').attr('disabled', false)
                                } else {
                                    thisPage.addClass('success')
                                    if(index === total - 1) {
                                        console.log("Last one")
                                        if(!$('.page_url').hasClass('failed')) {
                                            console.log("looks valid")
                                            valid = true
                                            console.log('reached the end', valid)        
                                            $('#createDomainReportForm').unbind('submit').submit()

                                        }
                                    }
                                }
                            } else {
                                thisPage.addClass('failed')
                                valid = false
                                $('p.warning').text('There was a problem validating one or more of the pages.')
                                thisPage.focus()
                                $('#createDomainReportForm').addClass('failed')
                                $('#submitDomain').attr('disabled', false)
                                
                            }
                        }
                    })
                }
            }
        } else {
            $('p.warning').text('There was a problem with the domain.')
            $('input#domain').val("Error: Enter Domain").addClass('failed').focus()
            $('#createDomainReportForm').addClass('failed')
            $('#submitDomain').attr('disabled', false)
        }
    },

    // Render interface to load existing User Settings
    renderLoadUserSettings() {

        AdminReports.getSettingsNameList()
        .then((settings) => {
            console.log("settings", settings)
            const output = settings.settings.map((setting) => {
                return `<li class="user-settings-name">${ setting.settings_name }</li>`
            })

            $('#load_settings .modal-body .settings-list').html(output);
        })
    },

    // Render interface to save/overwrite User Settings
    renderUserSaveSettings() {

        AdminReports.getSettingsNameList()
        .then((settings) => {
            console.log("settings", settings)
            const output = settings.settings.map((setting) => {
            return `<li class="user-settings-name">${ setting.settings_name }</li>`
        })

        $('#save_settings .modal-body .settings-list').html(output);
        })
    },

    // Render report filtering interface
    insertFilterSet() {

        const displayCols = AdminReports.getDisplayCols()
        
        const operatorObjects = [ 
            { displayName: 'Equals', value:  '='},
            { displayName: 'Does Not Equal', value: '!='}, 
            { displayName: 'Contains', value: 'LIKE'},
            { displayName: 'Does Not Contain', value: 'NOT LIKE'},
            { displayName: 'Less Than', value: '<' },
            { displayName: 'Less Than or Equal to', value: '<=' },
            { displayName: 'More Than', value: '>' },
            { displayName: 'More Than or Equal to', value: '>=' },
        ]
        const currentFilterCount = $('#filter_selector .filter-setting').length
        const operators = operatorObjects.map((operator) => {
            return `<option value="${ operator.value }">${ operator.displayName }</option>`
        })
        const options = displayCols.map((column) => {
            return `<option value="${ column }">${ column }</option>`
        })

        const filterRow = `<div class="row filter-setting"><div class="col"><select class="form-control" name="filter[${ currentFilterCount }][column]"><option value="">Select Column</option>${ options }</select></div> <div class="col"><select class="form-control" name="filter[${ currentFilterCount }][operator]">${ operators }</select></div> <div class="col"><input type="text" class="form-control" name="filter[${ currentFilterCount }][value]" placeholder="Set value"/></div></div>`

        $('#filter_selector .modal-body').append(filterRow)
    },

    validateDomain(url, obj = {}) {
        return new Promise((resolve, reject) => {
            
            console.log("submitted ", url)
            $.ajax({
                type: 'POST',
                url: '/admin/reports/validate-domain/submit',
                headers: {'X-CSRF-TOKEN': $('meta[name="csrf_token"]').attr('content')},
                data: { domain: url },
                success: (response) => {
                    console.log("this works...", response)
                    resolve(response, obj)
                },
                error: (error) => {
                    reject(error, obj)
                }
            })
        })
    },

    // Page interactivity
    adminReportEvents() {
        return (

            $('.column-headers th .column-name').on('click', function(e) {
                e.preventDefault()
                const columnName = $(this).data('col_id')
                const form = ('#form-'+columnName)
                $(form).submit();
            }),
            
            // Inserts new row of filter settings into interface
            $('#add_filter').on('click', function(e) {
                e.preventDefault()
                AdminReports.insertFilterSet()
            }),

            // Delete row of filter settings
            $('.remove-filter').on('click', function(e) {
                e.preventDefault()
                const filter = $(this).parent().parent('.filter-setting').remove()
            }),
        
            $('input#page_limit').on('change', function() {
                $('#apply_page_limit').attr('aria-disabled', false)
            }),

            $('#loadSettings').on('click', function() {
                AdminReports.renderLoadUserSettings()
            }),

            $('#saveSettings').on('click', function() {
                AdminReports.renderUserSaveSettings()
            }),

            $('#setColumns').on('click', function() {
                AdminReports.renderColumnFields()
            }),

            // User Settings: Save or Overwrite?
            $('#save_settings_form').on('submit', function(e) {
                e.preventDefault()
                $('#save_settings .modal-body .error-message').html('')
        
                const form = $(this)
        
                const name = form.find('#settings_name').val()
        
                AdminReports.getSettingsNameList()
                .then((settings) => {
                    console.log("all settings", settings)
                    const list = settings.settings.filter((setting) => {
                        return setting.settings_name
                    })
                })
                .then((list) => {

                    // This needs checking. Should be a Simple validaiton.
                    console.log("comparing", list)
                    if(list.includes(name)) {
                        // Warn of overwriting
                        return confirm('Overwrite existing settings?')
                    
                    } else if(!name) {
                        // Name can't be blank.
                        $('#save_settings .modal-body .error-message').html('Settings name required.')
                        return false
                    }
                })
                .finally(() => {
                    // if valid, submit the save/overwrite
                    $('#save_settings_form').unbind('submit').submit()
                })
                .catch((error) => {
                    console.log("error", error)
                })

                // Highlight Settings name when selected (load settings modal)
                $(document.body).on('click', '#load_settings .settings-list li', function() {
                    $('#load_settings .settings-list li').removeClass('selected')
                    $(this).addClass('selected')
                })
            
                // Load User Settings
                $('#load_settings_form').on('submit', function(e) {
                    e.preventDefault()
                    const form = $(this)
                    
                    const selected = $('#load_settings .settings-list li.selected').text()
                    $('#load_settings_form input#selected_setting').val(selected)
                    if($('#load_settings_form input#selected_setting').val()) {
                        $('#load_settings_form').unbind('submit').submit()
                    }
                })
            }),
            
            // In the User Settings Save modal, clicking an existing setting will 
            // put the name in the input field (for overwriting settings)
            $(document.body).on('click', '.user-settings-name', function() {
                const name = $(this).text()
                $('#save_settings #settings_name').val(name);
            }),

            $(document.body).on('click', '#load_settings .settings-list li', function() {
                $('#load_settings .settings-list li').removeClass('selected')
                $(this).addClass('selected')
            }),

            $('#load_settings_form').on('submit', function(e) {
                e.preventDefault()
                const form = $(this)
                
                const selected = $('#load_settings .settings-list li.selected').text()
                $('#load_settings_form input#selected_setting').val(selected)
                if($('#load_settings_form input#selected_setting').val()) {
                    $('#load_settings_form').unbind('submit').submit()
                }
            }),

            // Create Report page
            $('#add_page').on('click', function(e) {
                e.preventDefault();
                const count = $('.page-urls input.page_url').last().data('count') + 1
                const deleteIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16"><path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/><path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/></svg>`
                const inputRow = `<div class="col-6 mt-2"><input type="text" name="page_url[]" placeholder="/page/subpage" class="form-control page_url" data-count="${ count }"></div><div class="col-2"><a href="#" class="remove-page-url btn" data-count="${ count }">${ deleteIcon }</a></div>`
                $('.page-urls').append(inputRow)
            }),


            $('#submitDomain').on('click', async function(e) {
                e.preventDefault()
                $(this).attr('disabled', true)
                
                // reset alerts, etc.
                $('#createDomainReportForm .success, #createDomainReportForm .failed').removeClass('success failed')
                
                // Inform user
                $('#createDomainReportForm p.warning').text('Validating. One moment...')
                
                await AdminReports.validateCreateReportForm()
                
                // if(validate) {
                //     console.log("ready to submit", validate)
                //     $('#createDomainReportForm').unbind('submit').submit()
                // } else {
                //     console.log ("validation failed", validate)
                // }
                        
            }),

            $(document.body).on('click', 'a.remove-page-url', function(e) {
                e.preventDefault()
                const count = $(this).data('count')
                const target = $('input[data-count="' + count + '"]')
                console.log(target)
                $(target).parent().remove()
                $(this).parent().remove()
            })

        )   
    }
}

export default AdminReports