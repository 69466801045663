const AdminDashboard = {

    async initDashboard() {
        AdminDashboard.fetchBestWorst()
        .then((response) => {
            AdminDashboard.renderBestWorstPanel(response)
        })

        AdminDashboard.fetchAverageScores()
        .then((response) => {
            AdminDashboard.renderAverageScores(response)
        })
    },

    fetchBestWorst() {
        return new Promise((resolve, reject) => {
            $.ajax({
                type: 'POST',
                url: '/fetch_best_worst',
                headers: {'X-CSRF-TOKEN': jQuery('meta[name="csrf_token"]').attr('content')},
                success: (response) => {
                    resolve(response) 
                },
                error: (error) => {
                    reject(error)
                }
            })
        })
    },

    fetchAverageScores() {
        return new Promise((resolve, reject) => {
            $.ajax({
                type: 'POST',
                url: '/fetch_average_scores',
                headers: {'X-CSRF-TOKEN': jQuery('meta[name="csrf_token"]').attr('content')},
                success: (response) => {
                    resolve(response) 
                },
                error: (error) => {
                    reject(error)
                }
            })
        })
    },

    truncateUrl(url, strLen = 48, separator = '...') {
        
            if (url.length <= strLen) return url;

            var sepLen = separator.length,
                charsToShow = strLen - sepLen,
                frontChars = Math.ceil(charsToShow/2),
                backChars = Math.floor(charsToShow/2);
        
            return url.substr(0, frontChars) + 
                   separator + 
                   url.substr(url.length - backChars);
      
    },

    async renderBestWorstPanel(data) {
        console.log("bestWorst data", data)


        const bestResult = data.top_eco_scores.map((site) => {
            return `<li><span class="url" title="${ site.site_submitted }">${ AdminDashboard.truncateUrl(site.site_submitted) }</span> <span class="score">${ site.ecograder_score }</span></li>`
        })

        const worstResult = data.bottom_eco_scores.map((site) => {
            return `<li><span class="url" title="${ site.site_submitted }">${ AdminDashboard.truncateUrl(site.site_submitted) }</span> <span class="score">${ site.ecograder_score }</span></li>`
        })

        const finalBest = `<li><h6>Best Scoring Pages</h6></li>` + bestResult.join(' ')

        const finalWorst = `<li><h6>Worst Scoring Pages</h6></li>` + worstResult.join(' ')

        $('#bestWorst .best-scores').html(finalBest)
        $('#bestWorst .worst-scores').html(finalWorst)
    },

    async renderAverageScores(data) {
        console.log("averageScore data", data)

        const averageEcograder = `<h6>Ecograder</h6><h2>${ data.ecograder_average }</h2>`

        const averageEmissions = `<h6>Emissions</h6><h2>${ data.emissions_average }</h2>`

        const total = `Out of ${ data.total_entries } reports.`

        $('#averages .eco-score').html(averageEcograder)

        $('#averages .emiss-score').html(averageEmissions)

        $('#averages .total-reports').html(total)
    }

}

export default AdminDashboard