const SubMetrics = {



    calculateSubmetricPercent() {
        let total = 0;
        jQuery('.submetrics input:checked').each((i, el) => {
            let val = jQuery(el).next('input[type="number"]').val();
            if (!isNaN(val)) {
                total += parseFloat(val);
            }
        });
        console.log(total);
        jQuery('.submetrics label .percent').html('');
        if (total > 0) {
            jQuery('.submetrics input:checked').each((i, el) => {
                let val = jQuery(el).next('input[type="number"]').val();
                let pct = jQuery(el).parent().find('label .percent');
                console.log(val);
                if (!isNaN(val)) {
                    pct.html(' (' + Math.round(val * 100 / total) + '%)');
                }
            });
        }
    },

    submetricsEvents() {

        // Metrics Edit Page
        $('.submetrics input').on('change', () => {
            console.log('change')
            SubMetrics.calculateSubmetricPercent()
        })
    }
}

export default SubMetrics