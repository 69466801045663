import showdown from 'showdown'


const ActionText = {

    showVariablesForNewEntry(){

        const trigger = document.getElementById('sub_new_trigger')
    
        const container = document.getElementById('new_available_vars')

        if (trigger) {
            trigger.addEventListener('change', (e) => {
                
                const triggerval = trigger.value
                if (triggerval) {
                    ActionText.getSubmetricVariables(triggerval)
                        .then((response) => {
                            
                            jQuery(trigger).parent().parent().find('div .submetric-vars').html('<p>' + response.join(', ') + '<p>')
                        })
                        .catch(error => { console.log('error', error) })
                } else {
                    jQuery(trigger).siblings('.submetric-vars').html('')
                }
            })
        }
        
    },

    // Previewing Action Text
    fetchActionTextText(id) {
        return new Promise((resolve, reject) => {
        
            $.ajax({
                type: 'POST',
                url: '/preview-action-text',
                headers: {'X-CSRF-TOKEN': jQuery('meta[name="csrf_token"]').attr('content')},
                data: { action_id: id },
                success: (response) => {
                    resolve(response)
                },
                error: (error) => {
                    reject(error)
                }
            })
        })
    },

    // NOTE: Is not a promise.
    renderActionTextPreview(action_id, report_id) {
        $.ajax({
            type: 'POST',
            url: "/render-action-text",
            headers: {'X-CSRF-TOKEN': jQuery('meta[name="csrf_token"]').attr('content')},
            data: { action: action_id, id: report_id }
        }).done(function(response) {
            // const converter = new showdown.Converter()
            // const parsed = converter.makeHtml(response)
            console.log("here's parsed", response)

            jQuery('#previewModal #parsedResult').html(response)
            
        }).fail(function(error) {
            console.log("ajax error", error)
        })
    },

    checkIfPassed(action_id, report_id) {
        return new Promise((resolve, reject) => {
            $.ajax({
                type: 'POST',
                url: "/check-action-text-score",
                headers: {'X-CSRF-TOKEN': jQuery('meta[name="csrf_token"]').attr('content')},
                data: { action: action_id, id: report_id },
                success: (response) => {
                    resolve(response)
                },
                error: (error) => {
                    reject(error)
                }
            })
        })
    },

    checkHasSecondaryTrigger(action_id) {
        const triggered = jQuery('#st_active_'+action_id).is(':checked')
        console.log("is triggered?", triggered)
        if(triggered){
            return true
        }
        return false
    },

    checkIfSecondaryPassed(action_id, report_id) {
        return new Promise((resolve, reject) => {
            $.ajax({
                type: 'POST',
                url: "/check-secondary-trigger",
                headers: {'X-CSRF-TOKEN': jQuery('meta[name="csrf_token"]').attr('content')},
                data: { action: action_id, id: report_id },
                success: (response) => {
                    resolve(response)
                },
                error: (error) => {
                    reject(error)
                }
            })
        })
    },

    // Setting Secondary Trigger on Action Text
    displaySecondaryTrigger(action_id) {
        
        const row = jQuery('#secondary_trigger_'+action_id)
        const result = [
            row.find('#selectSecondaryTrigger_'+action_id).val() + ' | ',
            row.find('#st_variable_'+action_id).val(),
            row.find('#st_operator_'+action_id).val(),
            row.find('#st_value_'+action_id).val()
        ]

        console.log("secondary text", result)
        row.find('.st-display').html(result.join(' '))
    },
    
    getSubmetricVariables(trigger) {
        return new Promise((resolve, reject) => {
            jQuery.ajax({
                type: 'POST',
                url: '/admin/get-submetric-vars',
                headers: {'X-CSRF-TOKEN': jQuery('meta[name="csrf_token"]').attr('content')},
                data: { submetric: trigger },
                success: (response) => {
                    resolve(response)
                },
                error: (error) => {
                    reject(error)
                }
            })
        })
    },

    updateSingleActionSecTrig(action_id) {
        return new Promise((resolve, reject) => {
            const data = {
                id: action_id,
                st_active: 1,
                selectSecondaryTrigger: $('#selectSecondaryTrigger_'+action_id).val(),
                st_operator: $('#st_operator_'+action_id).val(),
                st_variable: $('#st_variable_'+action_id).val(),
                st_value: $('#st_value_'+action_id).val(),
            }
            jQuery.ajax({
                type: 'POST',
                url: '/admin/edit-metrics/{{ metric_id }}/update-secondary-trigger',
                headers: {'X-CSRF-TOKEN': jQuery('meta[name="csrf_token"]').attr('content')},
                data: data,
                success: (response) => {
                    resolve(response)
                },
                error: (error) => {
                    reject(error)
                }
            })
        })
    },

    actionTextEvents() {

        

        return(

            ActionText.showVariablesForNewEntry(),
            
            // Opens modal and loads data for previewing Action Text on ActionText page
        
            $('.preview-action-text').on('click', function(e) {
                e.preventDefault()
                const id = $(this).data('id')
                // Reset the modal
                $('#selectReport').prop('selectedIndex',0)
                $('#parsedResult').html('')
                $('#didPassText').html('')
                const modal = $('#previewModal')
                modal.attr('data-action_id', '')
            
                // Display the text without variables parsed
                ActionText.fetchActionTextText(id)
                .then((response) => {
                    modal.attr('data-action_id', id)
                    modal.find('.modal-body #actionTextPreview').val(response)
                })
                .catch((error) => {
                    console.log("uh oh", error)
                })
            
                modal.modal('show')
            }),
        

            // Cancel and close buttons 
            $('#modalClose, #modalChangeClose, #modalCopyClose').on('click', function() {
                $('#previewModal').data('action_id', '')
                $('#change_action_id').val('')
                $('#copy_action_id').val('')
                $('#previewModal, #changeParentMetric, #copyToParentMetric').modal('hide')
            }),
        
            // Take an ActionText entry and attach it to a different Metric
            $('.move-action-text').on('click', function(e) {
                e.preventDefault()
                const id = $(this).data('id')
                $('#selectMetric').prop('selectedIndex', 0)
                $('#change_action_id').val(id)
                const modal = $('#changeParentMetric')
                modal.modal('show')
            }),

            // Duplicate the ActionText entry into the same Metric
            $('.copy-action-text').on('click', function(e) {
                e.preventDefault()
                const id = $(this).data('id')
                $('#selectMetricCopy').prop('selectedIndex', 0)
                $('#copy_action_id').val(id)
                const modal = $('#copyToParentMetric')
                modal.modal('show')    
            }),

            // The Trigger (Submetric) dropdown loads and displays the available variables
            $(document.body).on('change', '#actionTextTable select', function(e) {
                const trigger = $(this)
                const triggerval = trigger.val()
                if(triggerval) {
                    ActionText.getSubmetricVariables(triggerval)
                    .then((response) => {
                        $(trigger).parent().parent().find('td.submetric-vars').html('<p>'+response[0].join(', ')+'<p>')
                    })
                    .catch(error => { console.log('error', error) })
                } else {
                    $(trigger).parent().parent().find('td.submetric-vars').html('')
                }
            }),

            // Select which report to use in ActionText Preview modal, parse variables based on report, 
            // and test if this particular report would have fired off this ActionText
            $('#selectReport').on('change', function(e){
                e.preventDefault
                const action = $('#previewModal').attr('data-action_id')
                const report = $(this).val()
                var passText = ''
                if(report !== 'default') {
                    ActionText.renderActionTextPreview(action, report)
                if(ActionText.checkHasSecondaryTrigger(action)) {
                    ActionText.checkIfPassed(action, report)
                    .then((response1) => {
                        ActionText.checkIfSecondaryPassed(action, report)
                        .then((response2) => {
                            const passText = (response1 == 1 && response2 == 1) ? "This report would have triggered this action text." : "This report would NOT have triggered this action text."
                            $('#previewModal #didPassText').html(passText)
                        })
                    })
                } else {
                    ActionText.checkIfPassed(action, report)
                    .then((response) => {
                        console.log("action text score test", response)
                        const passText = (response == 1) ? "This report would have triggered this action text." : "This report would NOT have triggered this action text."
                        $('#previewModal #didPassText').html(passText)
                    })
                }

                $('#previewModal').modal('handleUpdate')
                } else {
                    var results = ActionText.renderGenericActionTextPreview(action)
                    passText = "Generic data cannot be used for pass/fail."
                    
                    $('#previewModal .compare #parsedResult').html(results)
                    $('#previewModal #didPassText').html(passText)
                }
            }),
        
            // Checkbox to indicate ActionText has Secondary Trigger
            $('.toggle-secondary-trigger').on('change', function(e) {
                if($(this).is(':checked')) {
                    const modal = $(this).siblings('.modal')
                    modal.modal('show')
                } else {
                    $(this).parent().find('.st-display').html('')
                }
            }),

            // For now just closes the modal and updates the ActionText page to display what was entered....needs to actually save.
            $('.save-secondary').on('click', function(e) {
                const action_id = $(this).data('action_id')
                // shit validation, but it'll do for now
                if($('#selectSecondaryTrigger_'+action_id).val()) {
                    ActionText.updateSingleActionSecTrig(action_id)
                    .then((response) => {
                        $(this).closest('.modal').modal('hide')
                        setSaveResponse(response)
                        ActionText.displaySecondaryTrigger($(this).data('action_id'))
                    })
                } else {
                    $(this).closest('.modal').modal('hide')
                    $('#st_active_'+action_id).prop('checked', false)
                }
            }),
            
            $('.close-st-modal').on('click', function(e) {
                e.preventDefault()
                $(this).closest('.modal').modal('hide')
            }),

            $('#actionTextTable select.primary-trigger').each(function () {
    
                const trigger = $(this)
                const triggerval = trigger.val()
                console.log("trig val", triggerval)
                if(triggerval) {
                    ActionText.getSubmetricVariables(triggerval)
                    .then((response) => {
                            console.log("response", response)
                            // const parsed = JSON.parse(response)
                            $(trigger).parent().parent().find('.vars').html('<p>' + response.join(', ') + '<p>')
                        })
                        .catch(error => { console.log('error', error) })
                } else {
                    $(trigger).parent().parent().find('td.submetric-vars').html('')
                }
            }),
            
            $('#actionTextTable .table-row .secondary-trigger').each(function() {
                
                const secondary_trigger = $(this).find('input.toggle-secondary-trigger')
                
                const secondary_trigger_active = secondary_trigger.is(':checked')
                
                if(secondary_trigger_active) {
                    const id = secondary_trigger.data('for_id')
                    console.log("this id", id)
                    ActionText.displaySecondaryTrigger(id)
                }
            })
        )
    }



}

export default ActionText