require('./bootstrap')

// import React from 'react'
// import { render } from 'react-dom'
// import { createInertiaApp } from '@inertiajs/inertia-react'
// import { InertiaProgress } from '@inertiajs/progress'

import Helpers from './Helpers'
import ActionText from './ActionText'
import SubMetrics from './SubMetrics'
import AdminDashboard from './AdminDashboard'
import AdminReports from './AdminReports'
import AddPagesDomainReports from './AddPagesDomainReports'


// To come: load these conditionally per page or something.
// (esp we don't want admin stuff showing on the front end)
// if (typeof site_submitted !== 'undefined' && typeof report_id
// !== 'undefined') {
    // ReportPage.initReportPage(site_submitted, report_id)
    // ReportPage.reportPageEvents()
//    ReportPage.initReportPage2(site_submitted, report_id)
// }


function waitForElm(selector) {
    return new Promise(resolve => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver(mutations => {
            if (document.querySelector(selector)) {
                resolve(document.querySelector(selector));
                observer.disconnect();
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    })
}


// cheap way to check if we're a on a certain page. Regex, so pass the full 
// pathname or slug, should work. See below for implementation. 
function isPage(page) {
    
    const current_url = window.location.pathname
    const regex = new RegExp(page)
    if(regex.test(current_url)) {
        console.log("is page", page)
        return true
    }
    return false
}

// Public pages first.
if(isPage('^\/admin\/edit-metrics\/sub-metrics$')) {
    SubMetrics.submetricsEvents()
} else if(isPage('^\/admin\/edit-metrics\/.*?\/action-text$')) {
    ActionText.actionTextEvents()
} else if(isPage('^\/admin$') || isPage('^\/admin\/dashboard$')) {
    AdminDashboard.initDashboard()
} else if(isPage('^\/admin\/reports$') || isPage('^\/admin\/reports\/create$')) {
    AdminReports.adminReportEvents()
} else if(isPage('^\/admin\/reports\/clients\/.*?\/domains\/.*?\/pages\/create$')) {
    AddPagesDomainReports.formEvents()
}

// $('.dropdown-toggle').on('click', function(e) {
//     e.preventDefault()
//     $(this).dropdown('toggle')
// })

const dropdowns = document.querySelectorAll('.dropdown-toggle')

for( let i = 0; i < dropdowns.length; i++ ) {
    dropdowns[i].addEventListener('click', (e) => {
        e.preventDefault()
        e.target.classList.toggle('show')
    })
}


function display_lighthouse_audit( ) {
    return new Promise((resolve, reject) => {
        
        $.ajax({
            type: 'POST',
            url: '/display_lighthouse_audit',
            data: { _token: '{{ csrf_token() }}' },
            success: (response) => {
                resolve(response)
            },
            error: (error) => {
                reject(error)
            }
        })
    })
}

const signup = document.getElementById('signup')

signup.style.display = 'none'

waitForElm('.btn-signup').then(() => {

    const signupButtons = document.querySelectorAll('button.btn-signup')

    for( let i = 0; i < signupButtons.length; i++ ) {
        signupButtons[i].addEventListener('click', (e) => {
            e.preventDefault();
            
            signup.style.display = 'block';
        });
    }

    const closeSignupButtons = document.querySelectorAll('.close-btn')

    for( let i = 0; i < closeSignupButtons.length; i++ ) {
        closeSignupButtons[i].addEventListener('click', (e) => {
            e.preventDefault();
            signup.style.display = 'none';
        });
    }

    document.addEventListener('keyup', (e) => {
        if (e.key === 'Escape') {
            signup.style.display = 'none';
        }
    });

})