const AddPagesDomainReports = {

    validateDomain(url, obj = {}) {
        return new Promise((resolve, reject) => {
            
            console.log("submitted ", url)
            $.ajax({
                type: 'POST',
                url: '/admin/reports/validate-domain/submit',
                headers: {'X-CSRF-TOKEN': $('meta[name="csrf_token"]').attr('content')},
                data: { domain: url },
                success: (response) => {
                    console.log("this works...", response)
                    resolve(response, obj)
                },
                error: (error) => {
                    reject(error, obj)
                }
            })
        })
    },

    formEvents() {
        return (
            // Add Pages to Private Domain
            $('#add_page').on('click', function(e) {
                e.preventDefault();
                const count = $('.page-urls input.page_url').last().data('count') + 1
                const deleteIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16"><path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/><path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/></svg>`
                const inputRow = `<div class="col-6 mt-2"><input type="text" name="page_url[]" placeholder="/page/subpage" class="form-control page_url" data-count="${ count }"></div><div class="col-2"><a href="#" class="remove-page-url btn" data-count="${ count }">${ deleteIcon }</a></div>`
                $('.page-urls').append(inputRow)
            }),

            $('#submitDomain').on('click', async function(e) {
                e.preventDefault()
                $(this).attr('disabled', true)
                
                // reset alerts, etc.
                $('#createDomainReportForm .success, #createDomainReportForm .failed').removeClass('success failed')
                
                // Inform user
                $('#createDomainReportForm p.warning').text('Validating. One moment...')
                
                await AddPagesDomainReports.validateCreateReportForm()
                
                // if(validate) {
                //     console.log("ready to submit", validate)
                //     $('#createDomainReportForm').unbind('submit').submit()
                // } else {
                //     console.log ("validation failed", validate)
                // }
          
            }),

            $(document.body).on('click', 'a.remove-page-url', function(e) {
                e.preventDefault()
                const count = $(this).data('count')
                const target = $('input[data-count="' + count + '"]')
                console.log(target)
                $(target).parent().remove()
                $(this).parent().remove()
            })
        )
    },

    testFunction() {
        console.log("test function")
    }
                
}

export default AddPagesDomainReports